import React, { useState } from 'react';
import TextField from '../Components/TextField';
import { requestPasswordReset } from '../api';

import logo from '../images/logo.png';

const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState();

  const onChange = (event) => {
    const { value } = event.target;
    setError(!value || value.length === 0);
    setEmail(value);
  };

  const onClickSubmit = (event) => {
    event.preventDefault();
    setError(null);
    if (!email) {
      return;
    }
    setDisabled(true);
    requestPasswordReset(email)
      .then((results) => {
        setError(results.message);
        setDisabled(false);
      })
      .catch((e) => {
        setError(e);
      });
  };

  return (
    <div className="login_container">
      <form onSubmit={onClickSubmit} className="login_container__form">
        <img
          src={logo}
          className="App-logo"
          alt="logo"
          style={{
            alignSelf: 'center',
          }}
        />
        <p className="login_container__header">{'Email Address'}</p>
        <TextField
          name={'email'}
          type={'email'}
          placeholder={'Enter your email address'}
          onChange={onChange}
          value={undefined}
        />
        <button type="submit" className="login_container__submit" disabled={disabled}>
          Submit
        </button>
        <a className="login_container__forgot-password" href="/login">
          Login
        </a>
      </form>
      {error && <p className="login_container__error">{error.toString()}</p>}
    </div>
  );
};

export default ForgotPassword;
