import React, { Component } from 'react';

class TextField extends Component {
  render() {
    const props = this.props;
    return <input {...props} />;
  }
}

export default TextField;
